$CBV0000 : #EFEEED;
$CBV000 : #D9D8E6;
$CBV00 : #C6C4DD;
$CBV01 : #AEB8DB;
$CBV02 : #9DB7DB;
$CBV04 : #5B77A8;
$CBV08 : #4F506F;
$CBV11 : #A4A2C3;
$CBV13 : #6A88C2;
$CBV17 : #5D6E94;
$CBV20 : #C6CCD8;
$CBV23 : #8B9BAC;
$CBV25 : #6C7A8C;
$CBV29 : #484551;
$CBV31 : #D2D7E7;
$CBV34 : #7785AA;
$CV0000 : #FDF0ED;
$CV000 : #FCE8EC;
$CV01 : #E3C2DE;
$CV04 : #BD8AE6;
$CV05 : #C77FAF;
$CV06 : #A26B98;
$CV09 : #5D496A;
$CV12 : #D3ACD4;
$CV15 : #A783B5;
$CV17 : #746D9B;
$CV20 : #DFDCE3;
$CV22 : #BEBDCF;
$CV25 : #938FAD;
$CV28 : #5C5E76;
$CV91 : #E6D0D3;
$CV93 : #D4B6C0;
$CV95 : #B494A2;
$CV99 : #625159;
$CRV0000 : #FFF7F1;
$CRV000 : #FFF2F0;
$CRV00 : #FFE9EC;
$CRV02 : #FFCEED;
$CRV04 : #FF76A2;
$CRV06 : #F85DB3;
$CRV09 : #CD4479;
$CRV10 : #FFE1EA;
$CRV11 : #F9BBC0;
$CRV13 : #FF8FAD;
$CRV14 : #F26383;
$CRV17 : #B8507C;
$CRV19 : #AD496B;
$CRV21 : #FFBCCE;
$CRV23 : #FF9CB5;
$CRV25 : #F1617D;
$CRV29 : #C84557;
$CRV32 : #F4ABB4;
$CRV34 : #DD7C9C;
$CRV42 : #FFA79B;
$CRV52 : #F8C1E0;
$CRV55 : #E485B6;
$CRV63 : #E8AFD8;
$CRV66 : #A95C8D;
$CRV69 : #81494A;
$CRV91 : #F3D8DB;
$CRV93 : #E0B0BC;
$CRV95 : #BC8797;
$CRV99 : #614D4F;
$CR0000 : #FDF4E9;
$CR000 : #FFEFE3;
$CR00 : #FFE3D4;
$CR01 : #FFD7C2;
$CR02 : #FFAC8F;
$CR05 : #ED5D47;
$CR08 : #D75341;
$CR11 : #FFD4C2;
$CR12 : #FFBFA7;
$CR14 : #F76D67;
$CR17 : #E55951;
$CR20 : #FFC5BC;
$CR21 : #FFB1AB;
$CR22 : #FF8682;
$CR24 : #E75E5A;
$CR27 : #CD4D4D;
$CR29 : #BB3E45;
$CR30 : #FFD7C9;
$CR32 : #FFA3A2;
$CR35 : #E85764;
$CR37 : #BC4655;
$CR39 : #99424F;
$CR43 : #FF8897;
$CR46 : #BB434E;
$CR56 : #9F5561;
$CR59 : #874545;
$CR81 : #F6C1CF;
$CR83 : #EC9EB4;
$CR84 : #D46E87;
$CR89 : #7C4540;
$CYR0000 : #FFF3E3;
$CYR000 : #FFE5CD;
$CYR00 : #FFCCA9;
$CYR01 : #FFBD97;
$CYR02 : #FF9F71;
$CYR04 : #FF8C36;
$CYR07 : #F9642D;
$CYR09 : #E8593A;
$CYR12 : #FFA457;
$CYR14 : #FF9048;
$CYR15 : #FF963E;
$CYR16 : #FF8726;
$CYR18 : #DB653F;
$CYR20 : #FFDCAE;
$CYR21 : #FFC675;
$CYR23 : #EF9D46;
$CYR24 : #E98D30;
$CYR27 : #BB5A31;
$CYR30 : #FEF3D8;
$CYR31 : #FFD07D;
$CYR61 : #FFC4A3;
$CYR65 : #FF9E68;
$CYR68 : #FC6932;
$CYR82 : #FFD59B;
$CY0000 : #F6FFD2;
$CY000 : #F7FFB9;
$CY00 : #F9FF9C;
$CY02 : #FFEE62;
$CY04 : #FFF13A;
$CY06 : #FFF000;
$CY08 : #FFCB12;
$CY11 : #FFF780;
$CY13 : #FFEE60;
$CY15 : #FFCE2B;
$CY17 : #FFB42B;
$CY18 : #FFBC08;
$CY19 : #FFB424;
$CY21 : #FFD47A;
$CY23 : #FFD184;
$CY26 : #ECAC42;
$CY28 : #C1A072;
$CY32 : #FFD58F;
$CY35 : #FFC046;
$CY38 : #FFA634;
$CYG0000 : #FBFAC6;
$CYG00 : #F9F57F;
$CYG01 : #E8F152;
$CYG03 : #C7D54C;
$CYG05 : #A8DA2E;
$CYG06 : #90D94B;
$CYG07 : #79CA42;
$CYG09 : #45B046;
$CYG11 : #D9F4A4;
$CYG13 : #AED44E;
$CYG17 : #84A251;
$CYG21 : #F8EC72;
$CYG23 : #B1CC3C;
$CYG25 : #A6BC40;
$CYG41 : #B0E8A1;
$CYG45 : #64A56A;
$CYG61 : #D6DEB0;
$CYG63 : #92A26B;
$CYG67 : #667D4E;
$CYG91 : #CDBD90;
$CYG93 : #B1AA6B;
$CYG95 : #A28A4E;
$CYG97 : #7B6F51;
$CYG99 : #615C44;
$CG0000 : #E6F8E7;
$CG000 : #DBF8E3;
$CG00 : #C1F3D6;
$CG02 : #67D488;
$CG03 : #54CA72;
$CG05 : #38AC54;
$CG07 : #558F53;
$CG09 : #00944C;
$CG12 : #B1DDA6;
$CG14 : #6CBA4B;
$CG16 : #149265;
$CG17 : #288758;
$CG19 : #0E8F57;
$CG20 : #F0F5BE;
$CG21 : #B1C790;
$CG24 : #95AD72;
$CG28 : #4F6F57;
$CG29 : #456150;
$CG40 : #E8EDBE;
$CG43 : #A8B665;
$CG46 : #607B57;
$CG82 : #ABBC7E;
$CG85 : #83926C;
$CG94 : #83946A;
$CG99 : #5E5E41;
$CBG0000 : #EAF9F0;
$CBG000 : #DBF7F1;
$CBG01 : #85E6EA;
$CBG02 : #4CD9E8;
$CBG05 : #00BACB;
$CBG07 : #00939F;
$CBG09 : #00878E;
$CBG10 : #D7F3E3;
$CBG11 : #C2F2DE;
$CBG13 : #3ED1B9;
$CBG15 : #00BFA4;
$CBG18 : #408784;
$CBG23 : #7BDEC1;
$CBG32 : #7DE2C4;
$CBG34 : #61DAAC;
$CBG45 : #3DD4CF;
$CBG49 : #009489;
$CBG53 : #7ED8D8;
$CBG57 : #2C9D99;
$CBG70 : #EFF7EF;
$CBG72 : #7EAEAB;
$CBG75 : #628786;
$CBG78 : #4A6B6A;
$CBG90 : #E6E5CE;
$CBG93 : #9EA388;
$CBG96 : #6C725D;
$CBG99 : #696D60;
$CB0000 : #E7F9F1;
$CB000 : #D4F6F2;
$CB00 : #B8F1F1;
$CB01 : #8DE8ED;
$CB02 : #48D1EC;
$CB04 : #00B7D9;
$CB05 : #00A0D0;
$CB06 : #0086B6;
$CB12 : #7CD8EF;
$CB14 : #00A0C8;
$CB16 : #0097C9;
$CB18 : #2D70AB;
$CB21 : #A4DAEF;
$CB23 : #6AA6D7;
$CB24 : #33ACE3;
$CB26 : #387EB6;
$CB28 : #185EBD;
$CB29 : #405AB9;
$CB32 : #85D7F5;
$CB34 : #5C9ABC;
$CB37 : #175D7E;
$CB39 : #434F6F;
$CB41 : #B7DEEC;
$CB45 : #5B9EC3;
$CB52 : #94CDD5;
$CB60 : #E2EBEE;
$CB63 : #9BC1E6;
$CB66 : #4378C3;
$CB69 : #4245A3;
$CB79 : #504695;
$CB91 : #B3DAE4;
$CB93 : #8BBFD3;
$CB95 : #64A6C2;
$CB97 : #49768F;
$CB99 : #445465;
$CE0000 : #FFF4E4;
$CE000 : #FFEEDC;
$CE00 : #FFE1C7;
$CE01 : #FFD5BD;
$CE02 : #FFBC9E;
$CE04 : #CC8C8C;
$CE07 : #B4544E;
$CE08 : #C15D4E;
$CE09 : #9B4D44;
$CE11 : #FCC39E;
$CE13 : #E69E7A;
$CE15 : #C8785B;
$CE17 : #AC6556;
$CE18 : #744B44;
$CE19 : #9E5246;
$CE21 : #FFCEA0;
$CE23 : #C18260;
$CE25 : #A56E5A;
$CE27 : #83584A;
$CE29 : #6E4C44;
$CE30 : #FFE2BE;
$CE31 : #EEC694;
$CE33 : #DE9C6F;
$CE34 : #D9996C;
$CE35 : #C48455;
$CE37 : #9C6147;
$CE39 : #9D5940;
$CE40 : #F1E8D4;
$CE41 : #F8E4C5;
$CE42 : #EACDA8;
$CE43 : #D0B18C;
$CE44 : #9A7F60;
$CE47 : #78594B;
$CE49 : #4F4540;
$CE50 : #FFEFD5;
$CE51 : #FFE3BD;
$CE53 : #F3C895;
$CE55 : #D4A36E;
$CE57 : #9A6A4E;
$CE59 : #715245;
$CE70 : #D8C1B6;
$CE71 : #BB9F96;
$CE74 : #9E827B;
$CE77 : #7F655F;
$CE79 : #675551;
$CE81 : #EDDDB5;
$CE84 : #A58757;
$CE87 : #746048;
$CE89 : #4E463E;
$CE93 : #FFBDA7;
$CE95 : #FFB68D;
$CE97 : #ED915A;
$CE99 : #AC6240;
$CC00 : #F6F7EF;
$CC0 : #F0F3ED;
$CC1 : #E5EAE7;
$CC2 : #D3DBDC;
$CC3 : #BBC6CB;
$CC4 : #A2AFB8;
$CC5 : #85929C;
$CC6 : #6D7881;
$CC7 : #5F676F;
$CC8 : #55575D;
$CC9 : #505155;
$CC10 : #4C4749;
$CT0 : #ECEBE3;
$CT1 : #DEDDD5;
$CT2 : #CECCC5;
$CT3 : #B5B3AD;
$CT4 : #9A9893;
$CT5 : #7E7D79;
$CT6 : #6D6B68;
$CT7 : #585654;
$CT8 : #423F3F;
$CT9 : #434040;
$CT10 : #474444;
$CN0 : #E7E9E3;
$CN1 : #DCDFDA;
$CN2 : #C9CDCB;
$CN3 : #ABB1B1;
$CN4 : #93999B;
$CN5 : #767B7D;
$CN6 : #626668;
$CN7 : #58595A;
$CN8 : #515051;
$CN9 : #4D4A4A;
$CN10 : #494445;
$CW00 : #F7F5E9;
$CW0 : #F3F1E4;
$CW1 : #EAE5D6;
$CW2 : #E1DBCB;
$CW3 : #CEC7B6;
$CW4 : #B5AD9B;
$CW5 : #989081;
$CW6 : #827A6E;
$CW7 : #68625A;
$CW9 : #5A554F;
$CW8 : #4B4743;
$CW10 : #4A4745;
$C100 : #494344;
$C110 : #000000;
$CFV2 : #5A6EDC;
$CFRV1 : #FF9ADF;
$CFYR1 : #FFB352;
$CFY1 : #FFE446;
$CFYG1 : #F8FF77;
$CFYG2 : #71C047;
$CFBG2 : #4DD8E6;
$CFB2 : #009EF1;