.file-tb {
  display: inline-block;
  position: relative;
  padding-right: 23px;

  img.thumbnail {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  img.loading {
    height: 100px;
  }

  a.suppress, a.download {
    position: absolute;
    right: 0px;
    img {
      width: 20px;
    }
  }
  a.suppress {
    top: 0px;
  }
  a.download {
    top: 28px;
  }
}