.page-config {
  padding: 0 30px 300px;
  h1 {
    font-size: 22px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 11px;
    tr {
      &:nth-child(even) {background: #eee; }
      &:nth-child(odd) {background: #fff; }
    }
    th {
      font-weight: 200;
    }
    td, th {
      border: 1px solid #555;
    }
    td {
      font-size: $BASEFONTSIZE !important;
      font-weight: 400 !important;
      padding: 2px 5px;
      vertical-align: top;
      
    }
    .in-select {
      
      height: auto;
      select {
        padding: .1em 1.4em .1em .1em;
      }
    }
    .in-autocomplete {
      input {
        height: auto;
        padding: 2px 5px;
      }
    }

    .in-bool {
      display: block;
      img {
        width: 15px;
      }
    }
  }
}