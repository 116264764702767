
@mixin mat-shadow-1-anim {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    z-index: 1000;
  }
}

@mixin mat-shadow-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

@import "copic";
@import "roboto";
// @import "san-francisco";
@import "montserrat";
@import "photon";
@import "ol";
@import "autocomplete";
@import "quill";
@import "viewer";
@import "mods";

// $MAINFONT: 'Montserrat';
$MAINFONT: 'roboto';
$BASEFONTSIZE: 13px;
$ORANGE_CCBP:#E57C0C;
$ORANGE_CCBP_CLAIR_1:#fef4e9;
$ORANGE_CCBP_CLAIR_2:#fde9d3;
$ORANGE_CCBP_BORD: #f7b46c;


* { font-family: $MAINFONT; }
body { margin: 0; background-color: #fff; }

a, a:active, a:focus{ outline: none; /* Works in Firefox, Chrome, IE8 and above */ }

select option { color: black; font-family: 'roboto',Arial, Helvetica, sans-serif;}

h1 { 
  font-weight: 300;
  border-bottom: 1px solid #999;
}

.pb-10 { padding-bottom: 10px; }
.pr-20 { padding-right: 20px !important; }

.mr-2 { margin-right: 2px; }
.ml-20 { margin-left: 20px; }

.fs-11 { font-size: 11px !important; }
.fs-12 { font-size: 12px !important; }
.fs-13 { font-size: 13px !important; }
.fs-14 { font-size: 14px !important; }
.fs-15 { font-size: 15px !important; }
.fs-16 { font-size: 16px !important; }
.fs-17 { font-size: 17px !important; }
.fs-18 { font-size: 18px !important; }
.fs-19 { font-size: 19px !important; }
.fs-20 { font-size: 20px !important; }
.fs-21 { font-size: 21px !important; }
.fs-30 { font-size: 30px !important; }

.mt-10 { margin-top:  10px !important; }

.txt-center { text-align: center !important; }
.txt-left { text-align: left !important; }
.txt-right { text-align: right !important; }
.txt-bold { font-weight: 600 !important; }
.txt-red { color: $CR17 !important; }

.lh-12 { line-height: 12px; }

.w-80 { width: 80px; }
.w-100 { width: 100px; }
.w-150 { width: 150px; }
.w-200 { width: 200px; }

.middle-valign { vertical-align: middle !important; }

.blinking-alert {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.hide-on-mobile {
  @media all and (max-width: 1024px) {
    display: none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;
  }
}

.only-on-mobile {
  @media all and (min-width: 1024px) {
    display: none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;
  }
}

input {
  vertical-align: middle;
  height: 36px;
  box-sizing: border-box;
  font-size: $BASEFONTSIZE;
  width: 100%;
  border-radius: .5em;
  color: #444;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,0.04);
  padding: .6em 1.4em .5em .8em;
  margin-bottom: 5px;
  &.small {
    height: 25px;
  }
}

.commentaire {
  margin: 8px 0;
  background-color: #fff;
  
  border-radius: 5px;
  border: 1px solid #ddd;

  &.blue {
    & > span {
      background-color: $CB05;
    }
  }
  &.orange {
    & > span {
      background-color: #ee7f00;
    }
  }

  & > span {
    display: block;
    border-radius: 5px 5px 0 0;
    padding: 2px 5px;
    font-size: 11px;
    color: #fff;
    text-transform: uppercase;
    background-color: #555;
  }
  & > div {
    padding: 5px;
    font-size: $BASEFONTSIZE;
    text-align: justify;
    color: #333;
  }

  div.read-infos {
    text-align: right;
    a {
      font-weight: 500;
      color: $CB14;
    }
    span {
      text-transform: uppercase;
      font-weight: 700;
      color: #999999;
      font-size: 11px;
    }
  }
}

.echange-mail {
  background-color: #fafafa;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 4px 0;
  padding: 3px;

  a {
    box-sizing: border-box;
    display: block;
    position: relative;
    min-height: 16px;
    display: block;
    font-size: 10px;
    color: #666;
    padding-left: 20px;
    padding-top: 1px;
    text-decoration: none;
    text-transform: uppercase;
    img {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 12px;
    }
    
  }
}

#app {
  &.connected {
    padding-top: 60px;
  }
  &.mobile {
    padding-top: 0 !important;
  }
}

@keyframes colorBlinker {
  50% {
    color: transparent;
    background-color: transparent;
  }
}

.loading-indicator {
  position: fixed;
  bottom: 5px;
  right: 5px;
  font-weight: 500;
  font-size: 15px;
  animation: colorBlinker 1s ease-in-out infinite;
  color: #fff;
  background-color: $CR14;
  padding: 3px 10px
}

.change-layer-button {
  box-sizing: border-box;
  display: inline-block;
  margin-top: 5px;
  height: 24px;
  color: #333;
  text-decoration: none;
  position: relative;
  padding: 3px 3px 0 27px;
  border-radius: 3px;
  border: 1px solid #333;
  font-size: 12px;
  img {
    width: 20px;
    position: absolute;
    top: 0;
    left: 3px;
  }
}

.modal {
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  
  padding-top: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.80);

  &.image {
    padding-top: 0;
  }

  @media (max-width: 640px) {
    padding-top: 0px;
  }

  & > div.text {
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    @media (max-width: 640px) {
      border-radius: 0;
    }

    .title {
      background-color: #555;
      color: #eee;
      border-radius: 5px 5px 0 0;
      padding: 5px 10px;
      font-size: 20px;
      @media (max-width: 640px) {
        border-radius: 0;
      }
    }
    .message {
      padding: 5px 10px;
      font-size: 16px;
    }
    .component {
      padding: 5px 10px;
    }
    .buttons {
      text-align: right;
      padding: 5px 10px;
      .button { font-size: 18px; }
    }
  }

  & > div.image {
    text-align: center;;
    height: 100%;
    img {
      max-height: 100%;
    }
  }
}

.bandeau-bas {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 60px;
  text-align: center;
  background-color: transparent;
  // box-shadow: 0 -3px 5px 0px rgba(0,0,0,.1);
  color: #fff;
  padding: 0px 20px 20px;

  transition: all .3s ease-in-out;
  
  a {
    @include mat-shadow-1-anim;
    position: relative;
    box-sizing: border-box;
    padding: 10px 5px 0 8px;
    border-radius: 3px;
    background-color: $CYG09;
    // border: 1px solid darken($CYG09, 10);
    // box-shadow: 2px 2px 8px 3px rgba(0,0,0,.1);
    display: inline-block;
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    width: 100%;
    max-width: 400px;
    height: 47px;

    // &:hover {
    //   background-color: darken($CYG09, 10);
    // }

    i{
      position: absolute;
      top: 5px;
      left: 8px;
      font-size: 30px;
    }
  }
}

.bandeau-haut {
  box-shadow: inset 0px 10px 5px -5px rgba(0,0,0,0.2);
  
  box-sizing: border-box;
  padding: 0;
  // text-align: right;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 40px;
  // box-shadow: 0 0px 3px 2px rgba(0,0,0,.1);
  border-bottom: 1px solid #eee;
  background-color: #999;
  color: #fff;
  font-size: 14px;

  .in-select {
    display: inline-block;
    width: auto;
    padding-left: 10px;
    select {
      padding: 2px 19px 2px 5px;
      background-color: #555;
      color: #fff;
      font-family: $MAINFONT;
      font-weight: normal ;
      font-size: 11px;
      border-color: #fff;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
      option {
        font-family: $MAINFONT;
        color: #fff;
        font-weight: normal ;
      }
      &:focus {
        border-color: #aaa;
        box-shadow: none;
        color: #fff;
      }
    }
  }

  img {
    height: 30px;
    vertical-align: middle;
    margin: 4px 0 0 5px;
  }

  a.burger-menu {
    color: #fff;
    text-decoration: none;
    // background-color: $CW8;
    vertical-align: top;
    width: 35px;
    height: 35px;
    display: none;
    // border-radius: 50%;
    box-sizing: border-box;
    margin-top: 2px;
    font-size: 21px;
    padding: 5px 0 0 7px;
    @media (max-width: 1000px) {
      display: inline-block;
    }
    &.open {
      ul {
        display: block;
      }
    }
    ul {
      display: none;
      background-color: #999;
      position: fixed;
      top: 39px;
      left: 0;
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
      li {
        text-align: center;
        padding: 15px 0;
        &.router-link-active {
          background-color: #555;
          text-decoration: none;

          &.rouge {
            background-color: $CYG05;
          }

          a {
            text-decoration: none;
          }
        }
        a {
          color: #fff;
        }
      }
    }
  }

  a.router-link {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    padding: 8px 10px;
    height: 100%;
    vertical-align: top;
    box-sizing: border-box;

    &.arrow {
      text-decoration: none;
    }

    &.rouge {
      color: $CYG01;
    }

    &.router-link-active {
      background-color: #555;
      text-decoration: none;

      &.rouge {
        color: $CYG05;
      }
    }

    &:not(.arrow) {
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  .user-infos {
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #555;
    padding: 10px 5px 0 5px;

    a {
      color: #fff;
      text-decoration: none;
      display: inline-block;
      border: 1px solid #fff;
      font-size: 11px;
      border-radius: 5px;
      padding: 1px 3px 2px;
      margin-left: 5px;
      transition: background-color .15s ease-in-out;
      background-color: $CW8;
      &:hover {
        background-color: #aaa;
      }
    }
  }


}

@import "el-pagination";
@import "page-signalement";
@import "page-connexion";
@import "page-accueil";
@import "page-delegation";
@import "page-demande-inter";
@import "page-form-ticket";
@import "form-ticket";
@import "page-config";
@import "page-config-modules";
@import "page-config-profils";
@import "page-config-droits";
@import "page-stats";
@import "ins";
@import "photo-bouton";
@import "file-tb";

.vue-carte {
  .map {
    border: 1px solid #555;
    height: 300px;
    width: 100%;
    max-width: 600px;
  }
}

.heat-map {


  .map {
    border: 1px solid #555;
    height: 620px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
}

a.button {
  @include mat-shadow-1-anim;
  display: inline-block;
  background-color: $CW5;
  border-radius: 3px;
  padding: 2px 8px 2px;
  color: #fff !important;
  outline: none;
  font-size: $BASEFONTSIZE;
  // transition: all .15s ease-in-out;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  font-weight: 500;
  
  &.disabled {
    pointer-events: none;
    background-color: #ccc !important;
    color: #999 !important;
  }
  
  i {
    position: absolute;
    top: 5px;
    left: 8px;
  }
  
  &:hover {
    text-decoration: none;
  }
  
  &.small {
    font-size: 11px;
    padding: 2px 8px 2px;
  }
  
  &.grey {
    background-color: $CW5;
    &:hover {
      background-color: $CW4;
    }
  }
  
  &.red {
    background-color: $CR17;
    &:hover {
      background-color: darken($CR17, 10%);;
    }
  }
  
  &.green {
    background-color: $CYG09;
    &:hover {
      background-color: darken($CYG09, 10%);
    }
  }
  &.blue {
    background-color: $CB05;
    &:hover {
      background-color: darken($CB05, 10%);
    }
  }
  &.yellow {
    background-color: $CY38;
    &:hover {
      background-color: darken($CY38, 10%);
    }
  }
  &.violet {
    background-color: $CB69;
    &:hover {
      background-color: darken($CB69, 10%);
    }
  }
}

.photos-gallery {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li { display: inline-block; cursor: pointer;}
}

.liste-tickets {
  .fond {
    position: fixed;
    background-color: rgba(0,0,0,.5);
    top:0;
    left:0;
    width:100%;
    height: 100%;
    z-index: 1400;
  }

  .bouton-fermer {
    position: fixed;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    top: 10px;
    left: 10px;
    z-index: 9000;
    width:35px;
    height:35px;
    padding: 5px;
    background-color: $CR14;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    img {
      width:25px;
    }
  }

  .contenu {
    position: fixed;
    width: calc(100% - 100px);
    top: 10px;
    left:50px;
    background-color: #fff;
    height: auto;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    z-index: 9000;
    padding: 10px;
    max-height: calc(100% - 50px);
    overflow-y: auto;
  
   
    table {
      border-collapse: collapse;
      border: 1px solid #eee;
      tr {
        background-color: #fff;
        transition: background-color 0.15s ease-in-out;
        &:hover { 
          background-color: #fafafa;
        }
      }
      th, td {
        padding : 3px 4px;      
      }
      th {
        font-size: $BASEFONTSIZE + 2;
        font-weight: 400;
        text-align: left;
        background-color: #555;
        color: #fff;
      }
      font-size: 13px;
      td {
        border: 1px solid #eee;
        border-width: 1px 0 1px 0;
        &.status {
          text-align: center;
          img {
            width: 25px;
          }
        }

        &.priorite-ticket {
          font-weight: normal;
          font-size: $BASEFONTSIZE;
          text-align: center;
          padding: 0 3px;
          &.priorite-null { background-color: #f0f0f0; }
          &.priorite-1    { background-color: $CYG11; }
          &.priorite-2    { background-color: $CY11; }
          &.priorite-3    { background-color: $CR11; }
          &.priorite-4    { background-color: $CBG11; }
        }

        &.description {
          // text-align: justify;
          // white-space: nowrap;
          // overflow: hidden;
          div {
            max-width: 100% !important;
            overflow: hidden;
          }
          a {
            color: $CB66;
            text-decoration: none;
            font-weight: 500;
            span.categorie {
              text-transform: uppercase;
              display: inline-block;
              font-size: 10px;
              padding-bottom: 5px;
              font-weight: 500;
              color: #999;
            }
          }
        }
      }
    }
  }
}