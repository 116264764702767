.page-signalement {
  * { font-family: 'Montserrat' !important };
  $NB_PAGES : 9;
  $LARGEUR_SIGNALEMENT : 600px;
  $LARGEUR_BOUTON: 33.3333%;
  $HAUTEUR_ENTETE: 90px;
  padding-top: 0;
  overflow-x: hidden;
  $CURRENT_STEP: 2;
  width: $LARGEUR_SIGNALEMENT;
  margin: 0 auto;
  max-width: 100%;

  @mixin div-choix {
    position: relative;
    box-sizing: border-box;
    height: calc(100vh - #{$HAUTEUR_ENTETE});
    width: 100%;
  }

  .fond-haut {
    height: $HAUTEUR_ENTETE;
    background-color: $ORANGE_CCBP;
    position: fixed;
    width: 100%;
    top:0;
    left:0;
  }

  div.step-indicator {
    z-index: 900;
    box-sizing: border-box;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: $LARGEUR_SIGNALEMENT;
    max-width: 100%;
    padding-bottom: 10px;
    .button.valide-choix {
      display: inline-block;
      // z-index: 950;
      width: 250px;
      font-size: 13px;
      // position: absolute;
      // bottom: 60px;
      // left: 50%;
      // margin-left: -125px;
      // box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
      padding: 5px 0;
      margin-bottom: 18px;
    }
    img {
      display: inline-block;
      width: 15px;
    }
  }

  & > div.main-content {
    width: $NB_PAGES * 100%;
    margin-left: -$CURRENT_STEP * 100%;
    transition: all .2s ease-in-out;
    position: relative;
    float: left;
    height: 100vh;

    & > div {
      width: 100% / $NB_PAGES;
      float:left;
      overflow: hidden;

      a.lien-vos-signalements {
        display: block; 
        text-align: center;
        margin: 10px 5px 0;
        text-decoration: none;
        padding: 3px 0;
        color: $ORANGE_CCBP;
        font-weight: bold;
        border: 1px solid $ORANGE_CCBP_CLAIR_2;
        background-color: lighten($ORANGE_CCBP_CLAIR_1, .2);
      }

      & > div {
        text-align: center;

        h1 {
          margin-top: 0;
          border: none;
          text-align: center;
        }

        &.entete {
          background-color: $ORANGE_CCBP;
          padding-top: 5px;
          color: #fff;
          box-sizing: border-box;
          text-align: center;
          margin: 0 auto 0px auto;
          width: $LARGEUR_SIGNALEMENT;
          max-width: 100%;
          padding-bottom: 20px;
          position: relative;
          height: $HAUTEUR_ENTETE;
          h1 {
            margin-bottom: 5px;
            font-size: 20px;
          }
          img {
            height: $HAUTEUR_ENTETE - 10px;
          }
          a {
            text-decoration: none;
            color: #fff;
            display: inline-block;
            position: absolute;
            bottom: 10px;
            left: 5px;
            &.right {
              right: 5px;
              left: auto;
            }
          }

          div.uploadProgress {
            position: absolute;
            width: 104px;
            height: 11px;
            bottom: 10px;
            right: 5px;
            border-radius: 7px;
            border: 1px solid #fff;
            & > div {
              position: absolute;
              left: 2px;
              top: 2px;
              width: 100px;
              height: 7px;
              border-radius: 5px;
              background-color: #fff;
            }
          }
        }

        &.alerte {
          font-size: 12px;
          border: 1px solid #f7886c;
          margin: 5px 5px 0 5px;
          background-color: #f7dad2;
          padding: 5px;
          color: #942000;
        }

        &.details-ticket {
          @include div-choix;
          overflow: auto;
        }

        &.vos-signalements {
          @include div-choix;
          a {
            box-sizing: border-box;
            padding: 5px 5px 5px 30px;
            display: block;
            position: relative;
            border-bottom: 1px solid #888;
            text-decoration: none;
            color: #555;
            font-size: 12px;
            // margin: 10px;

            img {
              position: absolute;
              width: 20px;
              top: 5px;
              left: 5px;
            }
          }
        }


        &.choix-categorie {
          @include div-choix;
          height: auto !important;
          text-align: center;
          position: relative;
          width: $LARGEUR_SIGNALEMENT;
          max-width: 100%;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 10px 10px 0 10px;
          
          

          // border: 1px solid #333;
          div.icon {
            position: relative;
            padding-top: $LARGEUR_BOUTON;
            display: inline-block;
            
            color: $ORANGE_CCBP;
            width: $LARGEUR_BOUTON;
            text-align: center;
            
            a {
              box-sizing: border-box;
              top:5%;
              left:5%;
              position: absolute;
              width: 90%;
              height: 90%;
              border: 1px solid #F7B46C;
              padding: 8% 3% 0;
              
              text-decoration: none;
              img {
                width: 60%;
                transition: all .5s ease-in-out;
                margin-bottom: 2%;
              } 
  
              span {
                
                display: inline-block;
                transition: all .5s ease-in-out;
                color: #E47B0C;
                font-weight: 700;
                font-size: 11px;
                width: 100%;
                vertical-align: middle;
                text-align: center;
                @media (min-width: 600px) {
                  font-size: 14px;
                }
              }
              
            }
  
            &:nth-child(even) {
              a { background-color: $ORANGE_CCBP_CLAIR_2; }
            }
            &:nth-child(odd) {
              a { background-color: $ORANGE_CCBP_CLAIR_1; }
            }
  
          }
        }

        &.choix-photo {
          @include div-choix;
          padding-top: 110px;
          text-align: center;
          a {
            display: inline-block;
            margin-top: 30px;
          }
        }

        &.choix-localisation {
          @include div-choix;
          .map, .input-container {
            max-width: 100% !important;
          }
        }

        &.choix-description, &.choix-coordonnees {
          @include div-choix;
          padding: 30px 10px;
          .label-form {
            text-align: left;
          }
          textarea {
            height: calc(100vh - #{$HAUTEUR_ENTETE + 180px});
          }
          .in-text {
            margin-bottom: 15px;
          }
          .rgpd {
            background-color: #eee;
            font-size: 11px;
            border: 1px solid #aaa;
            border-radius: 3px;
            padding: 5px;
            margin: 20px 0;
            color: #444;
            text-align: justify;
          }
          .error-message {
            background-color: #fdd;
            font-size: 11px;
            border: 1px solid #b99;
            border-radius: 3px;
            padding: 5px;
            margin: 20px 0;
            color: #444;
            text-align: justify;
          }
        }


        // &.choix-coordonnees {
        //   @include div-choix;
        //   padding: 30px 10px;
        // }

        &.choix-recap {
          @include div-choix;
          text-align: center;
          table {
            width: 100%;
            td {
              text-align: left;
              padding: 5px 3px;
              font-size: 13px;

              &.categorie {
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                color: $ORANGE_CCBP;
              }

              &.adresse {
                text-transform: uppercase;
                text-align: center;
              }

              &.description {
                border-top: 1px solid #555;
                border-bottom: 1px solid #555;
              }

              &.label {
                text-align: right;
                font-weight: 600;
              }

              &.photo {
                text-align: center;
              }
            }
          }
        }

        &.confirm-message {
          padding: 25px;
          text-align: justify;
          div.unique-id {
            text-align: center;
            color: #333;
            font-weight: 700;
            font-size: 25px;
            padding-top: 25px;
          }
          a.retour {
            display: block;
            text-align: center;
            color: $ORANGE_CCBP;
            font-size: 25px;
            margin-top: 25px;
          }
        }
      }
    }
  }
}