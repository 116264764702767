.page-form-ticket {
  h1 {
    text-align: center;
    border: none;
    margin: 0 0 20px 0;
    line-height: 25px;
    span {
      font-size: 12px;
      font-weight: 200;
      color: #666;
    }
  }

}