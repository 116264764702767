.page-config {
  div.droits {
    display: flex;
    .liste-profils, .liste-membres, .liste-membres-AD {
      margin: 0 10px;
      flex: 0 1 500px;
      h3 {
        font-weight: 200;
      }
      & > div {
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #fafafa;
        padding: 8px 0;

        table {
          border: none;
          tr {
            background-color: transparent; padding: 0 5px; height: 24px;
            &.new {
              .in-autocomplete {
                display: inline-block;
                a { text-decoration: underline; }
                li { padding: 2px 5px; }
              }
              td {
                padding-top: 10px;
              }
            }
          }
          td, th { border: none; background-color: transparent; padding: 0;}
          td {
            color: #333;
            font-size: 12px;
            img {
              width: 15px;
              margin-left: 5px;
            }
            &.small {
              width: 30px;
              text-align: center;
            }
            .in-select {
              display: inline-block;
              padding-left: 6px;
              select {
                padding-left: 6px;
              }
            }
          }
        }

        a {
          padding: 3px 5px;
          display: block;
          text-decoration: none;
          color: #333;
          font-size: 12px;
          
          &.profil {
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            &.active {
              background-color: #888;
              color: #fff;
              &:hover {
                background-color: #666;
              }
            }
            &:hover {
              background-color: #ddd;
            }
          }

          &.disabled {
            pointer-events: none;
            color: #888;
          }

          img {
            width: 15px;
          }
          
        }
      }
    }
  }
}