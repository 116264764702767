.in-date {

  position: relative;

  span.value {
    cursor: pointer;
    text-decoration: underline;
    color: #555;
    font-size: 12px;
  }

  &.read-only {
    span.value {
      cursor: auto;
      text-decoration: none;
    }
  }

  a.erase {
    text-decoration: none;
    color: $CR89;
    font-weight: 700;
  }

  .type-time {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #eee;

    & > span {
      font-size: 20px;
      font-weight: 500;

      &.title {
        flex: 0 0 50%;
        text-align: center;
        color: #777;
        font-size: 16px;
        display: inline-block;
        font-weight: 400;
      }
    }

    input {
      width: 20px;
      height: 20px;
      border-width: 0 0 1px 0;
      text-align: center;
      // font-size: 16px;
      padding: 0;
      height: 25px;
      border-radius: 0;
      box-shadow: none;
      font-size: 13px;
    }

    a {
      display: inline-block;
      flex: 1 1;
      text-align: center;
      span {
        display: inline-block;
        width:20px;
        height: 20px;
        font-weight: 500;
        box-sizing: border-box;
        padding-top: 2px;
        font-size: 18px;
        color: $CB14;
      }
    }
  }

  input.main-input {
    vertical-align: middle;
    height: 36px;
    box-sizing: border-box;
    font-size: $BASEFONTSIZE;
    width: 100%;
    border-radius: 5px;
    color: #444;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,0.04);
    padding: .6em 1.4em .5em .8em;
    margin-bottom: 5px;
  }
  ul{
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .input-wrapper{
    border: 1px solid #ccc;
    border-radius: 2px;
    vertical-align: middle;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    align-items: center;
    padding: 6px 10px 6px 4px;
    height: 32px;
    box-sizing: border-box;
  }
  .input{
    height: 100%;
    width: 100%;
    font-size: inherit;
    padding-left: 4px;
    box-sizing: border-box;
    outline: none;
  }
  .cancel-btn{
    height: $BASEFONTSIZE;
    width: 14px;
  }
  .date-panel{
    position: absolute;
    z-index: 5000;
    border: 1px solid #eee;
    box-sizing: border-box;
    width: 250px;
    padding: 2px 5px 5px;
    box-sizing: border-box;
    background-color: #fff;
    transform: translateY(4px);
  }
  .panel-header{
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }
  .arrow-left, .arrow-right{
    flex: 1;
    font-size: 20px;
    line-height: 2;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
  }
  .selected-date {
    text-align: center;
    font-size: 20px;
    width: 100%;
  }
  .year-range{
    font-size: 20px;
    line-height: 2;
    flex: 3;
    display: flex;
    justify-content: center;
  }
  .year-month-box{
    flex: 3;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }
  .type-year, .type-month, .date-list{
    background-color: #fff;
  }
  .year-box, .month-box{
    transition: all ease .1s;
    font-family: $MAINFONT, sans-serif;
    flex: 1;
    text-align: center;
    font-size: 20px;
    line-height: 2;
    cursor: pointer;
    background-color: #fff;
  }
  .year-list, .month-list{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    li{
      font-family: $MAINFONT, sans-serif;
      transition: all .45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      cursor: pointer;
      text-align: center;
      font-size: 20px;
      width: 33%;
      padding: 10px 0;
      &:hover{
        background-color: #6ac1c9;
        color: #fff;
      }
      &.selected{
        background-color: #0097a7;
        color: #fff;
      }
      &.invalid{
        cursor: not-allowed;
        color: #ccc;
      }
    }
  }
  .date-list{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .valid:hover{
      background-color: #eee;
    }
    li{
      transition: all ease .1s;
      cursor: pointer;
      box-sizing: border-box;
      border-bottom: 1px solid #fff;
      position: relative;
      margin: 2px;
      &:not(.firstItem){
        margin-left: 10px;
      }
      .message{
        font-family: $MAINFONT, sans-serif;
        font-weight: 300;
        font-size: 12px;
        position: relative;
        height: 20px;
        &.selected{
          .bg{
            background-color: rgb(0, 151, 167);
          }
          span{
            color: #fff;
          }
        }
        &:not(.selected){
          .bg{
            transform: scale(0);
            opacity: 0;
          }
          &:hover{
            .bg{
              background-color: rgb(0, 151, 167);
              transform: scale(1);
              opacity: .6;
            }
            span{
              color: #fff;
            }
          }
        }
        .bg{
          height: 20px;
          width: 100%;
          transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
          border-radius: 50%;
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
        }
        span{
          position: absolute;
          z-index: 20;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
      &.invalid{
        cursor: not-allowed;
        color: #ccc;
      }
    }
  }
  .weeks{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    li{
      font-weight: 500;
      font-size: 12px;
    }
  }
  .weeks, .date-list{
    width: 100%;
    text-align: center;
    .preMonth, .nextMonth{
      color: #ccc;
    }
    li{
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
    }
  }
}

.in-select, .in-text {
  height: 36px;
  &.error input {
    border: 1px solid #f76d67;
  }
  &.small {
    height: 25px;
  }
  span {
    box-sizing: border-box;
    // padding-top: 7px;
    display: inline-block;
    font-size: $BASEFONTSIZE;
  }
  .indication {
    font-size: 11px;
    margin-bottom: 10px;
    text-align: right;
    color: #f76d67;
  }
}

.in-select {
  select {
    display: block;
    font-size: $BASEFONTSIZE;
    font-family: $MAINFONT;
    font-weight: 500;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    // linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;

    &::-ms-expand {
      display: none;
    }
    &:hover {
      border-color: #888;
    }
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }

  }

  &.error {
    select {
      border: 1px solid $CR14;
      box-shadow: 0 0px 3px 1px rgba($CR14, 0.4);
    }
  }

}

.in-fichier {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  &.small {
    img {
      max-width: 100px;
      max-height: 100px;
    }
  }
  & > div {
    margin-left: 5px;

    &.label {
      font-size: 12px;
      flex: 0 0 100%;
    }
  }
  .button.rond {
    box-sizing: border-box;
    width: 26px;
    height: 25px;
    display: inline-block;
    padding: 3px;
    border-radius: 50%;
    img {
      width: 19px;
    }
  }

  a.gros-rond {
    box-sizing: border-box;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 15px;

    text-decoration: none;
    background-color: $ORANGE_CCBP;
    color: #fff;
    padding: 15px 20px 0 20px;
    img {
      width: 60px;
    }
  }

  a.gros-rond-gris {
    box-sizing: border-box;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 15px;

    text-decoration: none;
    background-color: #00a0c8;
    color: #fff;
    padding: 15px 20px 0 20px;
    img {
      width: 60px;
    }
  }
}

.in-file {
  text-align: center;
  a.rond {
    box-sizing: border-box;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    background-color: $CB05;
    color: #fff;
    padding: 7px 0 0 2px;
    img {
      width: 33px;
    }
  }

  &.signalement {
    a.rond {
      box-sizing: border-box;
      display: inline-block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      font-size: 15px;
  
      text-decoration: none;
      background-color: $ORANGE_CCBP;
      color: #fff;
      padding: 15px 20px 0 20px;
      img {
        width: 60px;
      }
    }
  }
}

.in-photo {
  text-align: center;
  a.rond {
    box-sizing: border-box;
    display: inline-block;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    font-size: 15px;

    text-decoration: none;
    background-color: $ORANGE_CCBP;
    color: #fff;
    padding: 15px 20px 0 20px;
    img {
      width: 50px;
    }
  }
}

.in-long-text {
  textarea {
    box-sizing: border-box;
    font-size: $BASEFONTSIZE;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    color: #444;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,0.04);
    padding: .6em 1.4em .5em .8em;
  }
  &.error {
    textarea {
      border: 1px solid $CR14;
      box-shadow: 0 0px 3px 1px rgba($CR14, 0.4);
    }
  }
}

.in-geo {
  /* Normalize box-sizing */
  // div, ul, li, a, section, nav, h1, h2, h3, h4, h5, h6, hr, input, textarea, footer, header {
  //   box-sizing: border-box;
  //   margin: 0;
  //   padding: 0;
  // }
  // ol, ul {
  //   list-style: outside none none;
  // }      
  // small {
  //   font-size: smaller;
  // }
  // h2 {
  //   padding: 20px;
  // }

  &.no-geoloc {
    .input-container {
      & > div {
        width: 100%;
        margin-left: 0px;
      }
    }
  }

  .input-container {
    width: 100%;
    max-width: 600px;
    height: 60px;
    position: relative;
    & > div {
      display: inline-block;
      width: calc(100% - 80px);
      margin-left: 80px;
      margin-top: 10px;
    }

    a.geoloc {
      position: absolute;
      box-shadow: 0 2px 0 2px rgba(0,0,0,.04);
      font-size: 10px;
      display: inline-block;
      text-align: center;
      background-color: $CN1;
      color: $CN4;
      

      padding: 5px;
      border-radius: 3px;
      text-decoration: none;
      transition: all .15s ease-in-out;
      
      width: 64px;

      pointer-events: none;

      &.active {
        pointer-events: all;
        background-color: $CB05;
        color: #fff;
      }

      &:hover {
        background-color: darken($CB05, 10%);
      }
    }

    input[type="text"], input[type="search"], textarea {
      font-size: 16px;
      background-color: white;
      border: 1px solid #cccccc;
      border-width: 0 0 1px 0;
      border-radius: 2px;
      // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
      color: rgba(0, 0, 0, 0.75);
      display: block;
      font-family: inherit;
      height: auto;
      padding: 2px;
      width: 100%;
    }

  }

  /* Style input */
  
  body, input, textarea {
    color: rgba(0, 0, 0, 0.75);
    // font: 15px/20px "open_sansregular",sans-serif;
  }
  /* Set map size */
  .map {
    border: 1px solid #555;
    height: 300px;
    width: 100%;
    max-width: 600px;
    @media (max-width: 640px) {
      height: 200px;
    }
  }
  /* Set parameters on controls (geocoder and reverse)*/
  // input.photon-input {
  //   // width: auto;
  // }



  .ol-photon-geocoder {
    left: calc(50% - 200px);
    top: 0.5em;
  }
  .ol-photon-reverse-geocoder {
    background: none repeat scroll 0 0 white;
    bottom: 0.5em;
    left: calc(50% - 200px);
    padding: 10px;
    text-align: center;
    width: 400px;
  }

  &.mobile {
    height: 100%;
    position: relative;
    .change-layer-button {
      display: none;
    }
    .input-container {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 150;
      width: calc(100% - 10px);

      input {
        font-size: 14px;
        padding: 3px;
      }

      & > div {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        margin: 0;

        
      }
      a {
        position: absolute;
        // width: 100%;
        top: 30px;
        right: 0px;
      }
    }
    .map {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      border: none;
      height: 100%;
      z-index: 100;

      .ol-attribution {
        display: none;
      }
      .ol-zoom {
        display: none;
      }
      .ol-rotate {
        top: 35px;
      }
    }
  }
}

.in-autocomplete {
  // height: $formLineHeight;
  position: relative;
  input[type="text"] {
    width: auto;
  }
  .autoComplete-list {
    width: auto;
    position: absolute;
    left: 0;
    right: auto;
    box-shadow: 0px 0px 5px rgba(0,0,0,.25);
    li.autoComplete_result {
      margin: 0;
      font-size: $BASEFONTSIZE;
      color: #555;
      max-width: auto;
      padding: 10px 5px;
      cursor: pointer;
      margin-bottom: -1px;

      .autoComplete_highlighted {
        font-weight: 400;
        color: #000;
        
      }

      &:hover {
        color: #000;
        background-color: #eee;
      }
    }
  }
}

.in-duration {
  div > div {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    
    span {
      display: inline-block;
      width:50px;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
    a {
      flex: 1 0 50px;
      box-sizing: border-box;
      display: inline-block;
      font-size: 12px;
      color: #444;
      text-decoration: none;
      height: 20px;
      border: 1px solid #aaa;
      border-width: 1px 0 1px 1px;
      text-align: center;
      padding-top: 0px;
      background-color: #eee;

      &:first-child, &.first {
        border-radius: 5px 0 0 5px;
      }
      &:last-child, &.last {
        border-width: 1px;
        border-radius: 0  5px 5px 0;
      }
    }
  }
  // div {
  //   display: flex;
  //   align-items: center;
  //   width: 100%;
  //   input.small {
  //     box-sizing: border-box;
  //     width: 50px;
  //     margin-right: 5px;
  //     padding: .4em .2em .5em .2em;
  //     text-align: center;
  //     font-size: $BASEFONTSIZE;
  //   }
  //   a {
  //     flex: 1 0 25px;
  //     box-sizing: border-box;
  //     display: inline-block;
  //     font-size: 12px;
  //     color: #444;
  //     text-decoration: none;
  //     height: 20px;
  //     border: 1px solid #aaa;
  //     border-width: 1px 0 1px 1px;
  //     text-align: center;
  //     padding-top: 0px;
  //     width: 25px;
  //     margin-top: -4px;
  //     background-color: #eee;
      

  //     &.active {
  //       font-weight: 500;
  //       background-color: #fff;
  //     }

  //     &.last {
  //       border-width: 1px;
  //       border-radius: 0  5px 5px 0;
        
  //     }
  //     &.first {
  //       border-radius: 5px 0 0 5px;
  //     }
  //   }
  // }
}

.in-bool {
  display: inline-block;
  a {
    color: #333;
    text-decoration: none;
  }
  &.small {
    img { height: 13px; }
  }
  img {
    height: 20px;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
    }
  }
}

.in-number {
  input {
    text-align: right;
    max-width:70px;
    padding: 0 2px;
  }
}

.in-file-direct {
  text-align: center;

  .in-file {
    margin-top: 10px;
  }
  .file-label {
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .button.rond {
    box-sizing: border-box;
    width: 26px;
    height: 25px;
    display: inline-block;
    padding: 3px;
    border-radius: 50%;
    img {
      width: 19px;
    }
  }
}

.in-multi-choices {
  display: inline-block;
  position: relative;
  .list {
    font-size: 14px;
    text-decoration: none;
    color: $CB66;
    & > span {
      display: inline-block;
      margin: 0 2px;
    }
  }
  .choice {
    position: absolute;
    top: -15px;
    
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    z-index: 1000;

    .tous-aucun {
      text-align: center;
      a {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: $CB66;
      }
    }

    .valider {
      text-align: center;
    }

    .choices-list {
      display: flex;
      width: 400px;
      flex-wrap: wrap;
      & > div {
        box-sizing: border-box;
        flex: 0 0 33.333%;
        display: block;
        padding: 2px;
        & > a {
          display: block;
          text-decoration: none;
          font-size: 12px;
          padding: 2px;
          border-radius: 3px;
          text-align: center;
          border: 1px solid #555;
          background-color: $CR00;
          color: #555;
          &.selected {
            background-color: $CG12;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.in-select-buttons {
  a {
    @include mat-shadow-1-anim;
    box-sizing: border-box;
    display: inline-block;
    background-color: $CW5;
    border-radius: 3px;
    padding: 2px 8px 2px;
    color: rgba(255,255,255,.5);
    outline: none;
    font-size: $BASEFONTSIZE;
    // transition: all .15s ease-in-out;
    position: relative;
    text-align: center;
    text-decoration: none !important;
    font-weight: 500;
    margin: 0 3px;
    
    &.disabled {
      pointer-events: none;
      background-color: #ccc !important;
      color: #999 !important;
    }
    
    &.selected {
      // font-weight: bolder;
      color: #fff;
    }
  }
}