.form-ticket {
  .header-message {
    text-align: center;
    color: #333;
    background-color: #e2f4f8;
    border: 1px solid #999;
    padding: 5px;
    font-weight: 400;
    font-size: $BASEFONTSIZE + 2;
    margin-bottom: 10px;

    &.text-message {
      &.type-warning { background-color: #FEEBC9; }
      &.type-error { background-color: rgb(254, 204, 201); }
      table {
        margin: 0 auto;
      }
      img {
        width: 24px;
      }
    }

    .in-select {
      display: inline-block;
      select {
        width: auto;
      }
    }
    a {
      @include mat-shadow-1-anim;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 5px;
      font-size: $BASEFONTSIZE + 2;
      &.priorite-null, &:hover { background-color: #f0f0f0; }
      &.priorite-1    { background-color: $CR14; }
      &.priorite-2    { background-color: $CYR14; }
      &.priorite-3    { background-color: $CG14; }
      &.priorite-4    { background-color: $CB14; }
      &.priorite-5    { background-color: #666; }
    }
  }

  .main-form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & > div {
      flex: 1 1 25%;
      // width: 33.333333%;
      min-width: 300px;
      max-width: 600px;
      box-sizing: border-box;
      padding: 0 5px 0 5px;


      .in-geo, .vue-carte {
        padding: 0 10px;
        box-sizing: border-box;
      }

      
      
      .in-geo .input-container {
        height: 55x;
        input {
          font-size: $BASEFONTSIZE;
          background-color: transparent;
        }
      }

    }
  }

}