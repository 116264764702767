$BASEFONTSIZE: 12px;

.module {
  // @include mat-shadow-1;
  h2 {
    font-weight: 500;
    color: #555;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    background-color: #eee;
    padding: 2px 5px;
    box-sizing: border-box;
    a {
      float: right;
      margin-left: 5px;
    }
  }

  table {
    width: 100%;
    font-size: $BASEFONTSIZE;
    td.label {
      vertical-align: top;
      height: 22px;
      padding-top: 4px;
      color: #555;
      font-weight: 600;
      text-align: right;
      white-space: nowrap;
      &.middle {
        vertical-align: middle;
      }
    }
    td.field {
      div.read-only {
        padding-top: 3px;

        span.value {
          color: #555;
          font-size: 12px;
        }
      }

      .in-select, .in-date {
        height: 25px;
        
      }
      .in-select {
        select {
          padding: .1em 1.4em .1em .4em;
        }
      }

      .in-date {
        input {
          height: 25px;
        }
      }

      vertical-align: top;
      text-align: justify;
      &.photos {
        display: flex;
        flex-wrap: wrap;
        a {
          flex: 1 1 auto;
          // display: inline-block;
          // margin: 0 2px;
        }
      }

      &.description {
        font-size: $BASEFONTSIZE;
        color: #333;
        text-align: left;
      }

      &.priorites:hover {
        a {
          background-color: #999 !important;
          &:hover {
            box-shadow: 0px 0px 8px 3px rgba(0,0,0,.1);
            
          }
        }

      }

      a[class^="priorite-"] {
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        text-decoration: none;
        border-radius: 3px;
        margin-right: 15px;
        padding: 2px 4px;
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,.1);
        font-size: $BASEFONTSIZE;
        color: #888;
        font-weight: 500;

        
        &.priorite-null, &:hover { background-color: #f0f0f0;}
        &.priorite-1    { background-color: $CR14; color: #fff;}
        &.priorite-2    { background-color: $CYR14; color: #fff;}
        &.priorite-3    { background-color: $CG14; color: #fff;}
        &.priorite-4    { background-color: $CB14; color: #fff;}
        &.priorite-5    { background-color: #666; color: #fff;}

        &.inactive {background-color: #999;  }

        &.priorite-1:hover    { background-color: $CR14 !important; }
        &.priorite-2:hover    { background-color: $CYR14 !important; }
        &.priorite-3:hover    { background-color: $CG14 !important; }
        &.priorite-4:hover    { background-color: $CB14 !important; }
        &.priorite-5:hover    { background-color: #666 !important; }
      }
    }
  }

  &.avancement {
    & > div {
      display: flex;
      flex-wrap: wrap;
      & > div {
        display: inline-block;
        text-align: center;
        padding: 5px;
        
        &.etape-avancement {
          font-weight: 500;
          color: #fff;
          flex: 1 1 20%;
          border: 1px solid #aaa;
          margin: 0 3px;
          width: 25%;
          background-color: $CB14;
          font-size: 10px;
        }
  
        &.desc-avancement {
          font-size: $BASEFONTSIZE;
          flex: 0 0 100%;
          box-sizing: border-box;
          margin: 10px 0;
        }
  
        &.disabled {
          background-color: #ddd;
          color: #999;
        }
        &.valide {
          background-color: #7bc800;
          color: #fff;
        }
        &.invalide {
          background-color: #c83c00;
          color: #fff;
        }
  
        span {
          display: inline-block;
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
  }
}

.module.mod-dom-com-2 {
  .in-bool {
    // margin-right: 20px;
    width: 180px;
    margin: 5px 0;
  }
  td.label {
    width: 160px;
  }
  th {
    padding-top: 10px;
    color:#555;
    text-align: left;
    // text-decoration: underline;
  }
}

.module.mod-dom-info-sujet {
  .label {
    font-size: 12px;
    font-weight: 600;
    color: #555;
  }
  .liste-sujets, .liste-applis {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    a {
      @include mat-shadow-1-anim;
      flex: 0 0 80px;
      text-align: center;
      text-decoration: none;
      font-size: 12px;
      color: #333;
      margin: 5px;
      padding: 5px 0;
      border-radius: .5em;
      background-color: #eee;
      color: #999;
      &.active {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        z-index: 1000;
        background-color: #fff;
        color: #000;
      }
      img {
        width: 25px;
      }
    }
  }
  .liste-sujets a { height: 60px; }
  .liste-applis a { height: 18px; }
}