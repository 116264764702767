.page-connexion {
  div.bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: #05c0ff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23e57c0c' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23aa5c09' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23d88600' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%239f6200' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23c98e00' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23936800' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23ba9600' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23876d00' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23aa9d00' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%237b7100' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%239aa300' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%236e7400' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    z-index: 0;
  }
  & > div.form {
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    margin: 50px auto 10px;
    background-color: $ORANGE_CCBP;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,.3);

    div.error {
      font-size: 13px;
      text-align: center;
      color: #fff;
      font-weight: 700;
      padding: 10px;
      margin: 10px 0;
      border-radius: 5px;
      background-color: darken($color: $ORANGE_CCBP, $amount: 10);
    }

    div.label {
      color: #fff;
      padding-bottom: 8px;
    }

    input {
      font-size: 16px;
      &:focus {
        box-shadow: 0px 0px 8px 2px rgba(255,255,255,.8)
      }
    }


    a {
      box-shadow: 0px 0px 8px 2px rgba(0,0,0,.1);
      display: block;
      text-align: center;
      background-color: lighten($color: $ORANGE_CCBP, $amount: 10);
      color: lighten($color: $ORANGE_CCBP, $amount: 50);
      text-decoration: none;
      padding: 15px;
      border-radius: 5px;
      margin-top: 20px;
      font-weight: 700;
      font-size: 18px;
      transition: all .2s ease-in-out;

      &:hover {
        box-shadow: 0px 0px 8px 5px rgba(0,0,0,.1);
      }
    }

  }
}