.page-config-profils {

  .info-drag {
    position: fixed;
    bottom: 0;
    right:0;
    padding: 0 5px;
    font-size: 16px;
    background-color: rgba(0,0,0,.8);
    text-align: center;
    color: #fff;
  }

  .mod-palette, .display-palette {
    position: fixed;
    top: 40px;
    background-color: #eee;
    z-index: 300;
    div, a {
      display: block;
      border: 1px solid #999;
      margin: 4px 5px;
      width: 150px;
      height: 25px;
      color: #333;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;
      padding-top: 3px;
      padding-left: 7px;
      background-color: #fff;
      text-align: left;
      &.inactive {
        color: #999;
        background-color: #fafafa;
      }
    }
  }
  .mod-palette { left: 0; }
  .display-palette { right: 0; }

  .drop-area {
    width: 150px;
    height: 2px;
    margin: 0 5px;
    padding: 6px 0;
    &.full-width {
      width: 630px;
    }
    &.over {
      hr {
        box-shadow: 0 0 1px 1px rgba(59, 153, 252, .7);
      }
    }
    &.over-error {
      hr {
        box-shadow: 0 0 1px 1px rgba(212, 18, 18, 0.7);
      }
    }
    hr {
      margin: 0 0 0 0;
      &.over {
        box-shadow: 0 0 1px 1px rgba(59, 153, 252, .7);
      }
    }
  }
  text-align: center;
  * {
    box-sizing: border-box;
    font-family: 'roboto' !important;
    font-size: 12px;
  }
  & > table {
    width: 1370px;
  }
  table {
    border-collapse: collapse;
    margin: 0 auto 20px;
    
    tr.new-display-form {
      td {
        border: none;
        text-align: left;
        padding-bottom: 15px;

        a.button {
          height: 35px;
          font-size: 20px;
          padding-top: 4px;
        }
      }
    }

    th.titre-colonne {
      text-align: center;
      font-size: 15px;
      text-transform: uppercase;
      color: #333;
      border: none;
    }

    th.categorie {
      text-align: left;
      font-size: 20px;
      color: #333;
      border: none;
      // background-color: #eee;
    }

    td.profil {
      text-align: left;
      border: none;
      &.actif {
        & > a {
          font-weight: bolder;
        }
      }
      a {
        font-size: 13px;
        text-transform: uppercase;
        color: #333;
        text-decoration: none;
      }
    }

    th {
      text-align: left;
    }
    th, td {
      border: 1px solid #555;
      padding: 2px 10px;
      .masquable {
        position: relative;
        & > a {
          text-align: center;
          border: 1px solid #aaa;
          display: inline-block;
          font-weight: bold;
          font-size: 10px;
          color: #888;
          text-decoration: none;
          padding: 0 5px;
          border-radius: 5px;
          background-color: #fafafa;
          margin-bottom: 3px;
        }
        .contenu {
          display: none;
          th { text-align: right; padding: 0 5px 0 0; }
          td { padding: 0; }
          th, td { border: none; }
        }
        .taille {
          display: inline-block;
          font-size: 9px;
        }
        &.etendu {
          .contenu {
            display: block;
          }
          .taille {
            display: none;
          }
        }
      }
    }
  }

  table.layout {
    margin: 0 auto;
    td ,th{
      border: none;
      vertical-align: top;
      
      div.mod {
        border: 1px solid #999;
        margin: 0px 5px 0px;
        width: 150px;

        &.full-width {
          width: 630px;
        }

        div.entete {
          text-align: left;
          background: #fff;
          // border-radius: 3px;
          padding:1px 3px 3px;
          position: relative;
          height: 25px;
          padding-top: 3px;
          padding-left: 7px;

          &.ajouter {
            background: rgba(200,240,200,1);
          }

          & > a {
            color: #333;
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;
            &::before {
              content: '⯈ ';
            }
          }

          & > span.tag {
            color: #fff;
            background-color: #666;
            display: inline-block;
            font-weight: 900;
            width: 14px;
            text-align: center;
            height: 14px;
            border-radius: 3px;
            font-size: 10px;
          }
          .grab-button {
            display: block;
            position: absolute;
            right: 0px;
            top: 0px;
            width: 10px;
            height: 25px;
            cursor: all-scroll;
            z-index: 999;
            background-color: #aaa;
          }
          & > span.move-buttons {
            display: block;
            position: absolute;
            right: 2px;
            top: 0px;
            width: 38px;
            height: 28px;
            
            a {
              position: absolute;
              text-decoration: none;
              font-size: 20px;
              color:#00a0d0;
              &.disabled {
                pointer-events: none;
                color: #aaa;
              }
              &:nth-child(1) { top: -7px; left: 12px; }
              &:nth-child(2) { top: 2px; left: 12px; }
              &:nth-child(3) { top: -2px; left: 0px; }
              &:nth-child(4) { top: -2px; left: 24px; }
            }
          }
        }
        
        table.contenu {
          margin-top: 10px;
          display: none;
        }

        &.etendu {
          div.entete > a::before {
            content: '⯆ ';
          }
          table.contenu {
            display: table;
            width: 100%;
            textarea {
              padding: 3px;
            }
            select {
              padding: 0;
            }
          }
        }

      }
    }
    td {
      padding: 0 3px;
      &.contenant-layout {
        display: flex;
        flex-wrap: wrap;
      }
    }
    th {
      text-align: right;
      padding: 0 3px 0 3px;
    }
  }

}