.el-pagination {
  text-align: center;
  margin-bottom: 15px;
  color: #555;

  div.header {
    color: #555;
    font-size: 13px;
    margin-bottom: 5px;
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: #555;
    border-style: solid;
    border-color: #555;
    border-width: 1px 0 1px 1px;
    font-size: 13px;
    padding: 0 5px;

    &.active {
      pointer-events: none;
      background-color: #555;
      color: #fff;
    }

    &.disabled {
      pointer-events: none;
      background-color: #eee;
      color: #999;
    }

    &:first-child {
      border-radius: 2px 0 0 2px;
    }
    &:last-child {
      border-width: 1px;
      border-radius: 0 2px 2px 0;
    }
  }
}