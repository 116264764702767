.page-accueil {
  padding-bottom: 50px;

  .filters {
    $COULEUR_STATS: #eee;
    background-color: #eee;
    margin-bottom: 10px;
    border: 1px solid #aaa;
    border-width: 1px 0 1px 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > div {
      flex : 0 0 auto;
      margin: 10px 15px 10px;
      // background-color: lighten($COULEUR_STATS, 10%);
      // box-shadow: 4px 4px 4px 0px rgba(0,0,0,.05);
      border-radius: 8px;
      .title {
        border-radius: 8px 8px 0 0;
        display: inline-block;
        box-sizing: border-box;
        // background-color: $COULEUR_STATS;
        text-align: center;
        color: #333;
        font-size: 12px;
        padding: 3px;
      }

      input {
        display: inline-block;
        width: auto;
        font-size: 12px;
        padding: 2px;
        border-width: 0 0 1px 0;
        outline: none;
        height:auto;
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
      }


      .filters-buttons {
        display: inline-block;
        // font-size: 35px;
        text-align: center;
        color: #333;
        
        
        a {
          margin-left: 5px;
          text-decoration: none;
          color: #ccc;
          display: inline-block;
          border: 1px solid #ccc;
          background-color: #fff;
          border-radius: 5px;
          padding: 0 3px;
          font-size: 12px;
          
          transition: all .2s ease-in-out;

          &.active {
            background-color: #333;
            color: #fff;
            border: 1px solid #333;
          }
        }

        &:hover {
          a:not(.active) {
            color: #333;
            border: 1px solid #333;
          }
        }
      }
    }

    &.mobile {
      background-color: transparent;
      border: none;
      & > div {
        margin: 0;
      }
    }
  }
  .stats {
    $COULEUR_STATS: #fff;

    display: flex;
    justify-content: center;

    & > a {
      @include mat-shadow-1-anim;
      text-decoration: none;
      flex : 0 0 150px;
      margin: 0 15px 20px;
      background-color: $COULEUR_STATS;
      // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      border-radius: 8px;
      // border: 1px solid #aaa;
      // transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      color: #000;

      &.active {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        // transform: scale(1.02);
      }

      // &:hover {
      //   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      //   // transform: scale(1.02);
      // }
      .title {
        border-radius: 8px 8px 0 0;
        height: 55px;
        box-sizing: border-box;
        background-color: darken($COULEUR_STATS, 10%);
        text-align: center;
        color: #333;
        font-size: 12px;
        padding: 3px;
        img {
          width: 24px;
        }
      }

      .data {
        font-size: 35px;
        text-align: center;
        color: #555;
      }
    }
  }

  .export-excel {
    text-align:center;
    a {
      position: relative;
      display: inline-block;
      color: #333;
      text-decoration: none;
      font-size: 12px;
      padding: 5px 5px 5px 30px;
      border: 1px solid #555;
      border-radius: 4px;
      img {
        position: absolute;
        width: 20px;
        top:3px;
        left: 3px;
      }
    }
    margin-bottom: 5px;
  }

  table.liste-tickets {
    background-color: #fff;
    border: 1px solid #aaa;
    margin: 0 auto;
    // width: 100%;
    max-width: 1500px;
    font-size: $BASEFONTSIZE;
    border-collapse: collapse;
    tr {
      background-color: #fff;
      transition: background-color 0.15s ease-in-out;
      &.cloture { background-color: #eee; }
      &.dismissed { background-color: #fee; }
      &:hover { 
        background-color: #fafafa;
      }
    }
    th, td {
      padding : 3px 4px;      
    }
    th {
      font-size: $BASEFONTSIZE + 2;
      font-weight: 400;
      text-align: left;
      background-color: #555;
      color: #fff;
      // border-bottom: 1px solid #aaa;
      a {
        color: #fff;
        span {
          font-size: 12px;
        }
      }
    }
    td {

      &.cell-id {
        font-weight: 600;
        font-size: 11px;
        span {
          span {
            color: #555;
            font-weight: normal;
          }
        }
      }
      &.priorite-ticket {
        text-align: center;
      }

      &.priorite-null { background-color: #f0f0f0; }
      &.priorite-1    { background-color: $CR11; }
      &.priorite-2    { background-color: $CY11; }
      &.priorite-3    { background-color: $CYG11; }
      &.priorite-4    { background-color: $CBG11; }
      
      &.status-0 { color: $CR17; }
      &.status-1 { color: $CYG17; }
      &.status-2 { color: #aaa; }

      &.meta {
        font-size: 11px;
        text-align: center;
        i {
          font-size: 18px;
        }
      }

      &.description {
        // text-align: justify;
        // white-space: nowrap;
        // overflow: hidden;
        div {
          max-width: 100% !important;
          overflow: hidden;
        }
        a {
          color: $CB66;
          text-decoration: none;
          font-weight: 500;
          span.categorie {
            text-transform: uppercase;
            display: inline-block;
            font-size: 10px;
            padding-bottom: 5px;
            font-weight: 500;
            color: #999;
          }
        }
      }

      &.domaine {
        width: 30px;
      }

      &.lieu, &.demandeur, &.cell-status-texte { 
        font-size: $BASEFONTSIZE - 1px;
        position: relative;
        padding-left: 30px;
        img {
          position: absolute;
          top: calc(50% - 12px);
          left: 0;
        }
        span.commune {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 11px;
          color:#555;
        }
        span.adresse {
          color: #999;
        }
      }

      &.yellow { background-color: $CY11; }
      &.red { background-color: $CR11; }
      &.green { background-color: $CYG11; }
      &.violet { background-color: $CV12; }
      &.blink-red {
        background-color: $CR11;
        animation: bgBlinker .7s ease-in-out infinite;
      }
      @keyframes bgBlinker {
        50% {
          background-color: transparent;
        }
      }

      span.badge {
        box-sizing: border-box;
        font-size: $BASEFONTSIZE;
        padding-left: 3px;
        padding-top: 1px;
        display: inline-block;
        width: 22px;
        height: 22px;
        color: #fff;
        background-color: #aaa;
        border-radius: 50%;
        &.ccbp { background-color: $CYR24; }
        &.commune { background-color: $CB24; }
        &.batiment { background-color: $CYR24; }
        &.adresse { background-color: $CR24; }
        &.usager { background-color: $CG24; }
        &.mail { background-color: $CYR24; padding-left: 5px; padding-top: 0; }
      }

      &.identifiant {
        font-size: 11px;
        text-align: center;
      }

      img {
        width: 24px;
      }

      color: #222;
      
      max-width: 600px;
      border-top: 1px solid #ddd;
      
    }
  }

  .cards-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
}

.ticket-card {
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 15px auto;
  border-radius: 5px;
  float: left;
  width: calc(100%);
  // max-width: 800px;
  background-color: #fff;
  // clear: both;
  img { width: 24px; }
  

  .gauche {
    // float:left;
    box-sizing: border-box;
    width: 100%;
    padding-left: 5px;
    text-align: left;
    margin-right: 10px;

    & > div {
      display: inline-block;
    }

    .ticket-status {
      color: #fff;
      font-weight: bold;
      font-size: 13px;
      img {
        width: 13px;
      }
    }

    .identifiant {
      font-size:10px;
      color: #888;
      margin-top: 5px;
    }

    .priorite-ticket {
      font-weight: normal;
      font-size: $BASEFONTSIZE;
      text-align: center;
      padding: 0 3px;
      &.priorite-null { background-color: #f0f0f0; }
      &.priorite-1    { background-color: $CYG11; }
      &.priorite-2    { background-color: $CY11; }
      &.priorite-3    { background-color: $CR11; }
      &.priorite-4    { background-color: $CBG11; }
    }

    .date-ticket {
      font-size:10px;
      color: #555;
      margin-top: 10px;
    }
    .categorie {
      margin-bottom: 2px;
      color: #888;
      font-size: 11px;
    }

  }
  .droite {
    float: right;
    width: 70px;
    margin-left:10px;
    text-align: center;
    
    
  }

  .centre {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 3px;

    

    .description {
      // font-weight: normal;
      display: block;
      // white-space: nowrap; 
      overflow: hidden;
      // text-overflow: ellipsis;
      // max-height: 20px;
      color: #4378c3;
      text-decoration: none;
      font-size: $BASEFONTSIZE;
      
    }
  }


  table {
    border: none;
    background-color: #f2f2f2;
    margin-bottom: 0;
    margin-top: 0px;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #ddd;
    width: 100%;
    
    td, th, tr {
      border: none;
      text-align: left;
    }

    td {
      .info-titre {
        color: #888;
        font-size: 11px;
      }
      .info {
        font-weight: 600;
        color: #666;
        font-size: $BASEFONTSIZE;
        margin-bottom: 4px;
      }
    }

    td.bouton-ticket {
      vertical-align: middle;
      text-align: center;
      font-size: 21px;
      width: 26px;
      border-right: 1px solid #ddd;
      padding: 0;
      a {
        margin: 0 2px;
        color: #666;
        outline: none;
        position: relative;

        span.compteur {
          position: absolute;
          display: block;
          top:0;
          right:0;
          background-color: $CR29;
          font-size: 9px;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          color: #fff;

          &.bleu {
            background-color: $CB28;
          }
        }
      }

    }

    td.info-case {
      line-height: 12px;
      padding: 4px 4px 4px 5px;
      // &.info-demandeur {
      //   // width: 180px;
      //   // border-right: 1px solid #ddd;
        
      // }

      &.info-note {
        border-left: 1px solid #ddd;
      }

      &.info-visible {
        border-left: 1px solid #ddd;
        font-size: 22px;
        width: 30px;
        padding-top: 6px;
      }
    }
  }
}