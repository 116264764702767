.page-demande-inter, .page-form-ticket {
  background-color: #fff;
  width: 100%;
  // max-width: 600px;
  
  margin: 0 auto;
  padding: 1px 8px 80px 8px;
  
  // box-shadow: 0px 0px 15px 5px rgba(0,0,0,.10);
  // border-style: solid;
  // border-color: #dadada;
  // border-width: 0 1px 0 1px;

  box-sizing: border-box;

  .main-form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & > div {
      padding: 0 10px;
      flex: 1 1 400px;
      max-width: 500px;
    }
  }

  &.connected {
    // margin-top: 50px;
    border-width: 1px;
    @media (max-width: 600px) {
      border-width: 1px 0 1px 0;
    }
  }

  h1 {
    margin: 5px 0 20px 0;
    font-size: 28px;
    text-align: center;
    border: none;
    text-transform: uppercase;
  }

  h2 {
    font-weight: 500;
    color: #555;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    background-color: #eee;
    padding: 2px 5px;
    box-sizing: border-box;


  }

  @media (max-width: 600px) {
    border-width: 1px 0 1px 0;
  }

  .photos-list {
    text-align: center;
    img {
      margin: 0px 3px;
      display: inline-block;
    }
  }

  div.adresse {
    position: relative;
    height: 40px;
    border: 1px solid #aaa;
    border-radius: 5px;
    text-align: center;
    background-color: #eee;
    margin-bottom: 10px;
    color: #333;
    padding-top: 5px;
    line-height: 18px;

    padding-left: 30px;

    .coords {
      font-size: 10px;
    }


    i {
      position: absolute;
      color: #999;
      top: 7px;
      left: 5px;
    }
  }

  .choix-loc {
    text-align: center;
    margin-bottom: 20px;
    a {
      outline: none;
      display: inline-block;
      width: 45%;
      text-align: center;
      border: 1px solid #999;
      text-decoration: none;
      
      background-color: $CN1;
      color: $CN4;

      &.active {
        color: #333;
        background-color: #fff;
        // color: #fff;
      }


      &:first-child {
        border-radius: 10px 0 0 10px;
        border-width: 1px 0 1px 1px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  .in-select {
    margin-bottom: 5px;
  }
  div[class^="label"] {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    
    // padding-left: 10px;
  }

  div.label-form {
    margin-top: 30px;
    text-decoration: none;
  }

  div.choix-origine {
    text-align: center;
    display: flex;
    margin-top: 20px;
    a {
      flex: 1 1 50px;
      display: inline-block;
      color: #999;
      text-decoration: none;
      font-size: $BASEFONTSIZE;
      border: 1px solid #999;
      padding: 0 5px;
      border-width: 1px 0 1px 1px;
      background-color: #eee;

      &.active {
        background-color: #fff;
        color: #333;
      }

      &:last-child {
        border-width: 1px;
        border-radius: 0 8px 8px 0;
      }
      &:first-child {
        border-radius: 8px 0 0 8px;
      }
    }
  }

  div.choix-sur-categorie {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      @include mat-shadow-1-anim;
      flex: 0 1 110px;
      display: inline-block;
      font-size: 11px;
      // border: 1px solid #aaa;
      border-radius: .5em;
      color: #999;
      background-color: #eee;
      // font-weight: 600;
      width: 90px;
      // height: 60px;
      margin: 3px;
      text-align: center;
      padding: 8px 4px;
      text-decoration: none;
      // box-shadow: 0 1px 0 1px rgba(0,0,0,0.04);

      &.active {
        background-color: #fff;
        color: #333;
        // box-shadow: 0px 0px 8px 3px rgba(0,0,0,.1);
      }

      img {
        width: 32px;
      }
    }
  }

  div.label-inter-form {
    text-align: center;
    padding-left: 0;
  }

  div.label-sous-form, span.label-sous-form, td.label-sous-form {
    font-size: $BASEFONTSIZE;
    font-weight: 600;
    color: #555;
    padding-left: 20px;
    margin-bottom: 2px;
    padding-left: 0;
    margin-top: 3px;
  }

  div.category-icon {
    text-align: center;
    img {
      width: 50px;
    }
    span {
      font-weight: 700;
      font-size: 12px;
      color: $ORANGE_CCBP;
    }
  }
}
