ul.photon-autocomplete {
  position:absolute;
  background-color:#fff;
  z-index:1000;
  box-shadow:0 4px 9px #999;
  margin: 0;
  padding:0;
 }
 .photon-autocomplete li {
  min-height:40px;
  line-height:1em;
  padding:5px 10px;
  overflow:hidden;
  font-size:1em
 }
 .photon-autocomplete li strong {
   font-weight: 200;
  display:block
 }
 .photon-autocomplete li.on {
  background-color:#80b9df;
  cursor:pointer
 }
 .photon-autocomplete li.photon-no-result {
  text-align:center;
  color:#666;
  font-size:.9em;
  line-height:40px
 }
 .photon-autocomplete li .type {
  // font-family:open_sanslight;
  font-weight:400;
  float:right;
  font-size:.8em
 }
 .photon-autocomplete .photon-feedback {
  display:none;
  text-align:right;
  font-size:.8em;
  padding:3px;
  color:#999;
  border-top:1px solid #eee
 }
 .photon-input:focus {
  width:400px
 }
 