.page-stats {
  margin-top:-30px;
  padding-top: 80px;
  padding-bottom: 30px;
  background-color: #f1f4f6;
  .filters {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: #f1f4f6;
    top: 40px;
    left: 0;
    width: 100%;
    z-index: 8000;
    padding-top: 2px;

    .choix-stats {
      display: flex;
      a {
        display: inline-block;
        font-size: 12px;
        color: #aaa;
        border: 1px solid #888;
        text-decoration: none;
        padding: 2px 5px;
        border-radius: 5px 0 0 5px;
        background-color: #eee;

        &.active {
          background-color: $CB14;
          color: #fff;
          font-weight: 500;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
          border-width: 1px 1px 1px 0;
        }
      }
    }

    .filter {
      margin: 0 10px;
      .in-select {
        display: inline-block;
      }
    }
  }

  h2 {
    text-align: center;
    font-weight: lighter;
    text-transform: uppercase;
  }
  .all-cards {
    display: flex;
    flex-flow: column nowrap;
    justify-content: left;
    // gap: 10px;
    div.row { display: flex; flex-flow: row wrap; flex: 1 1 0; }
    div.column { display: flex; flex-flow: column nowrap; flex: 1 1 0; }

    .card {
      box-sizing: border-box;
      
      // &.w100 { flex: 2 1 100%; }
      &.w50 { flex: 1 1 50%; }
      &.w33 { flex: 1 1 33.333333%; }
      padding: 5px;
      & > div {
        width:100%;
        height: 100%;
        background-color: #fff;;
        // margin: 10px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        .title {
          border-bottom : 1px solid rgba(0, 0, 0, 0.125);
          padding: 10px;
        }
        .content {
          padding: 10px;
        }
      }
    }
  }
}

.data-table {
  font-size: 13px;
  border-collapse: collapse;
  width: 100% ;

  tr:nth-child(even) {background: #f1f4f6}

  td, th {
    border: 1px solid #555;
    padding: 2px 8px;
  }

  th {
    font-size: 12px;
    background-color: #555;;
    color: #fff;
    text-transform: uppercase;
  }
}

.amcharts-amexport-item {
  padding-top: 5px !important;
  background-color: #33C481 !important;
}

.amcharts-amexport-item-csv  {
  font-weight: bolder !important;
  color: #107C41 !important;
}