.page-delegation {
  table {
    margin: 0 auto;
    border-collapse: collapse;

    tr.active {
      background-color: $CYG05;
    }

    th, td {
      border: 1px solid #999;
      padding: 5px 10px;

      .in-date > span.value {
        font-size: 16px;
        color: $CB18;
      }
      .in-autocomplete > a {
        font-size: 16px;
        color: $CB18;
      }
    }

    th {
      background-color: #555;
      color: #fff;
      font-weight: normal;
    }
  }
}