.page-config-modules {

  h3 {
    padding-top: 0px;
    margin-top: 0px;
  }

  .layout {
    display: flex;
    & > div {
      padding: 0 15px 15px 15px;
      width: 20%;
      .module {
        padding-bottom: 15px;
        & > table {
          width: 100%;
          border: 1px solid #999;
          font-size: 9px;
          border-collapse: collapse;
          td {
            padding: 2px;
          }
          td.title {
            background-color: #eee;
            font-weight: 600;
            text-align: center;
          }
          td.label {
            text-align: right;
            color: #555;
            font-style: italic;
          }
        }
        .ruban {
          cursor: pointer;
          background-color: #eee;
          padding: 0 5px 2px 5px;
          text-align: center;
          span {
            font-size: 11px;
            color: #555;
            &.active {
              font-weight: 700;
              color: #333;
            }
          }
        }
        span {
          font-size: 11px;
        }
      }
    }
   
  }

  .choix-sur-categorie {
    .in-select {
      width: auto;
      display: inline-block;

      select {
        padding: .2em 1.4em .2em .8em;
      }
    }
  }

  .entete {
    font-size: 12px;
    margin-top: 10px;
    border: 1px solid #ccc;
    background-color: #fafafa;
    padding: 3px;
    textarea {
      font-size: 11px;
      height: 22px;
      padding: 2px;
    }
    .in-text {
      display: inline-block;
      width: auto;
    }
    a.button {
      margin-right: 2px;
      margin-top: 2px;
    }

    .move-buttons {
      a {
        color: #fff;
        text-decoration: none;
        display: inline-block;
        background-color: #888;
        width: 10px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #555;
        height: 16px;
      }
    }
  }

  h4 {
    font-weight: lighter;
    margin-bottom: 0px;
  }

  .add-module {
    margin-top: 20px;
    .in-select {
      width: auto;
      display: inline-block;

      select {
        padding: .2em 1.4em .2em .8em;
      }
    }


  }
}